import { jsx } from "react/jsx-runtime";
import { lazy, useMemo } from "react";
const LazyLink = lazy(
  () => import("./link-l8BLYKJj.js").then((module) => ({
    default: module.Link
  }))
);
function Link({ id: _id, name: _name, ...props }) {
  return /* @__PURE__ */ jsx(LazyLink, { ...props });
}
const useLinkContent = () => {
  return useMemo(
    () => ({
      link: {
        component: Link
      }
    }),
    []
  );
};
export {
  LazyLink as Link,
  useLinkContent
};
